import axios from 'axios';
import React, { useState } from 'react';
import './SignIn.css';
import Swal from 'sweetalert2';
import Logo from '../../assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

function SignIn() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
/* ----------------------------------------------------- SHOW PASSWORD ------------------------------------------------------------------------------- */
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
/* ----------------------------------------------------- LOGIN USER ------------------------------------------------------------------------------- */
  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!email || !password) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Please fill all fields!',
      });
      setLoading(false);
      return;
    }

    const loginData = {
      Email: email,
      Password: password
    };

    try {
      const response = await axios.post(`${apiUrl}/api/User/Signin`, loginData);
  
      if (response.status === 200) {
        const token = response.data.token;
        // Decode the token
        const decodedToken = jwtDecode(token);
        const userID = decodedToken.nameid; 
        const userRole = decodedToken.role; 
        const userEmail = decodedToken.email;
        const verified = decodedToken.isVerified;
  
        Swal.fire({
          icon: 'success',
          title: 'Login Successful',
          confirmButtonText: 'OK'
        });
  
        // Store data in localStorage
        localStorage.setItem("token", token);
        localStorage.setItem("user_id", userID);
        localStorage.setItem("user_email", userEmail);
        localStorage.setItem("role", userRole);
        localStorage.setItem("verified", verified);
  
        // Navigate based on the user's role
        if (userRole === "User") {
          navigate("/");
        } else if (userRole === "Admin") {
          navigate("/admin-panel");
        }
      } else if (response.status === 404) {
        Swal.fire({
          icon: 'error',
          title: 'Sign In Failed',
          text: "Email or Password is incorrect.",
          confirmButtonText: 'OK'
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Sign In Failed',
          text: "Something went wrong.",
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        Swal.fire({
          icon: 'error',
          title: 'Sign In Failed',
          text: "Email or Password is incorrect.",
          confirmButtonText: 'OK'
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Sign In Failed',
          text: 'An error occurred while signing in. Please try again later.',
          confirmButtonText: 'OK'
        });
      }
    } finally {
      setLoading(false);
    }
  };
/* ----------------------------------------------------- FORGOT PASSWORD ------------------------------------------------------------------------------- */
  const handleForgotPass = async () => {
    const { value: email } = await Swal.fire({
      title: 'Enter your email address',
      input: 'email',
      inputPlaceholder: 'Enter your email address',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to enter your email!';
        }
      }
    });

    if (email) {
      try {
        const response = await axios.post(`${apiUrl}/api/User/sendResetPassLink/${email}`);
        
        // Check the status correctly
        if (response.status === 200) { 
          Swal.fire({
            icon: 'success',
            title: 'A Reset Link Will Be Sent To You Shortly!',
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          Swal.fire({
            icon: 'warning',
            title: 'Invalid Email Format or Bad Request!',
            text: 'Please check your email and try again.',
          });
        } else if (error.response && error.response.status === 404) {
          Swal.fire({
            icon: 'warning',
            title: 'Email not found in our records!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'An Error Occurred!',
            text: error.message,
          });
        }
      }
    }
  };
/* ----------------------------------------------------- HTML ------------------------------------------------------------------------------- */
  return (
    <div className="signin__container">
      <div className='signin__header'>
        <img src={Logo} alt="Kempton Academy Logo" className='signin__logo' />
        <h1 className='signin__title'>Kempton Academy</h1>
      </div>

      <form className="signin__form" onSubmit={handleLogin}>
        <h2 className="signin__title">Sign In</h2>

        <div className="signin__inputGroup">
          <label htmlFor="email" className="signin__label">Email address</label>
          <input 
            type="email" 
            id="email" 
            className="signin__input" 
            placeholder="Enter email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
        </div>

        <div className="signin__inputGroup">
          <label htmlFor="password" className="signin__label">Password</label>
          <input 
            type={showPassword ? "text" : "password"} 
            id="password" 
            className="signin__input" 
            placeholder="Enter password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
        </div>

        <div className="signin__showPassword">
          <input 
            type="checkbox" 
            id="showPassword" 
            className="signin__checkbox" 
            onChange={togglePasswordVisibility} 
          />
          <label htmlFor="showPassword" className="signin__label">Show Password</label>
        </div>

        <button type="submit" className={`signin__button ${loading ? 'loading' : ''}`} disabled={loading}>
          {loading ? "Loading..." : "Submit"}
        </button>

        <div className="signin__forgotPassword">
          <p to="/forgot-password" className="signin__forgotPasswordLink" onClick={handleForgotPass}>Forgot password?</p>
        </div>

        <div className="signin__separator"></div>
        <p className="signin__text">
          Don't Have An Account? <Link to="/signup" className="signin__link">Sign Up!</Link>
        </p>

      </form>
    </div>
  )
}

export default SignIn;
