import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import './Contact.css';
import BG from '../../assets/bg.jpg';

function Contact() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
/* ----------------------------------------------------- SEND EMAIL ------------------------------------------------------------------------------- */
  const handleSend = async () => {
    if (!email || !fullName || !subject || !message) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Please fill all the fields!',
      });
      return;
    }

    setLoading(true);

    const concatenatedSubject = `${subject} - ${fullName}`;
    const contactData = {
      Email: email,
      Subject: concatenatedSubject,
      Message: message,
    };

    try {
      const response = await axios.post(`${apiUrl}/api/User/ContactUS`, contactData);

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Message Sent',
          text: 'Your message has been sent successfully!',
        });
        // Clear form fields after successful submission
        setEmail('');
        setFullName('');
        setSubject('');
        setMessage('');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to send your message. Please try again later.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while sending your message. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };
/* ----------------------------------------------------- HTML ------------------------------------------------------------------------------- */
  return (
    <div className='contact__main'>
      <img src={BG} alt='' className='pic__contact' />
        <div className='contact__container'>
          <h1 className='contact__title'>Contact Us</h1>
          <div className='contact__inputGroup'>
            <label className='contact__label'>Email*</label>
            <input
              type='email'
              className='contact__input'
              placeholder='Enter your email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className='contact__inputGroup'>
            <label className='contact__label'>Full Name*</label>
            <input
              type='text'
              className='contact__input'
              placeholder='Enter your full name'
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </div>

          <div className='contact__inputGroup'>
            <label className='contact__label'>Subject*</label>
            <input
              type='text'
              className='contact__input'
              placeholder='Enter subject'
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </div>

          <div className='contact__inputGroup'>
            <label className='contact__label'>Message*</label>
            <textarea
              className='contact__textarea'
              placeholder='Enter your message'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>

          <button
            className={`contact__btn ${loading ? 'loading' : ''}`}
            onClick={handleSend}
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send'}
          </button>
      </div>
    </div>
  );
}

export default Contact;
