import React from 'react'
import FCE from '../assets/facebook.png'
import Insta from '../assets/insta.png'
import './socialFooter.css'

function socialFooter() {
  return (
    <div className='socialFooter__main'>
        <h1> Our Socials !</h1>
        <div className='socialFooter__images'>
          <a href="https://www.facebook.com/profile.php?id=61566490934280&is_tour_completed" target="_blank" rel="noopener noreferrer">
            <img src={FCE} alt='Facebook' className='footer__social__img' />
          </a>
          <a href="https://www.instagram.com/kemptonacademy/" target="_blank" rel="noopener noreferrer">
            <img src={Insta} alt='Instagram' className='footer__social__img' />
          </a>
        </div>
    </div>
  )
}

export default socialFooter