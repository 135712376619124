import React, { useState } from 'react';
import axios from 'axios';
import './Footer.css';
import FCE from '../assets/facebook.png';
import Insta from '../assets/insta.png';
import Swal from 'sweetalert2';

function Footer() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
  const handleSend = async () => {
    if (!email || !subject || !message) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Please fill all the fields!',
      });
      return;
    }

    setLoading(true);

    const contactData = {
      Email: email,
      Subject: subject,
      Message: message,
    };

    try {
      const response = await axios.post(`${apiUrl}/api/User/ContactUS`, contactData);

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Message Sent',
          text: 'Your message has been sent successfully!',
        });
        // Clear form fields after successful submission
        setEmail('');
        setSubject('');
        setMessage('');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to send your message. Please try again later.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while sending your message. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };
/* ---------------------------------------------------------------------------------------------------------------------------------------------------- */
  return (
    <div>
      <div className='footer__content'>
        <div className='footer__social'>
          <h1 className='footer__social__title'> Our Socials !</h1>
          <div className='footer__images'>
          <a href="https://www.facebook.com/profile.php?id=61566490934280&is_tour_completed" target="_blank" rel="noopener noreferrer">
            <img src={FCE} alt='Facebook' className='footer__social__img' />
          </a>
          <a href="https://www.instagram.com/kemptonacademy/" target="_blank" rel="noopener noreferrer">
            <img src={Insta} alt='Instagram' className='footer__social__img' />
          </a>
          </div>
        </div>

        <div className='footer__touch'>
          <h1 className='footer__touch__title'>Contact Us</h1>
          <h1 className='footer__touch__header'>Email*</h1>
          <input
            type='text'
            className='footer__touch__input'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <h1 className='footer__touch__header'>Subject*</h1>
          <input
            type='text'
            className='footer__touch__input'
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <h1 className='footer__touch__header'>Message*</h1>
          <textarea
            className='footer__touch__message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            className={`footer__btn ${loading ? 'loading' : ''}`}
            onClick={handleSend}
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send'}
          </button>
        </div>
      </div>

      <div className='footer__copyright'>
        <p>Copyright © 2024 Kempton Academy. All rights reserved</p>
      </div>
    </div>
  );
}

export default Footer;
