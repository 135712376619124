import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';

function Verification() {
const navigate = useNavigate();
const apiUrl = process.env.REACT_APP_API_URL;

/* ----------------------------------------------------- VALIDATE TOKEN ------------------------------------------------------------------------------- */
useEffect(() => {
  const verifyAccount = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenFromURL = queryParams.get('token');

    if (!tokenFromURL) {
      navigate('/signin'); // Navigate to sign-in page if no token exists
      return;
    }

    const decoded = jwtDecode(tokenFromURL); // Decode the token after extracting it
    const email = decoded.email;

    try {
      const response = await axios.put(`${apiUrl}/api/User/verifyAccount`, { email }, {
        headers: {
          'Authorization': `Bearer ${tokenFromURL}`, // Send token in Authorization header
          'Content-Type': 'application/json' // Explicitly state the content type as JSON
        },
      });

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Account Verified',
          text: 'Your account has been successfully verified!',
          showConfirmButton: false,
          timer: 2000,
        });

        setTimeout(() => {
          navigate('/signin');
        }, 2000);
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Verification Failed',
          text: 'Account verification failed. Please try again later.',
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401 error specifically
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'This link has expired. Please request a new verification link.',
          showCancelButton: true,
          confirmButtonText: 'Send New Link',
          cancelButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) {
            resendVerificationLink(email); // Call the resend verification link function
          } else {
            navigate('/signin'); // Navigate back to sign-in if "OK" is clicked
          }
        });
      } else {
        console.error("Catch Error Verifying:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while verifying the account. Please try again later.',
        });
      }
    }
  };

  verifyAccount();
});

/* ----------------------------------------------------- DUMMY RESEND FUNCTION --------------------------------------------------------------------- */
const resendVerificationLink = async (email) => {
  try {
    const response = await axios.post(`${apiUrl}/api/User/sendverificationlink/${email}`);
    if(response.status === 200){
      Swal.fire({
        icon: 'success',
        title: `A New Verification Link Will Be Sent To Your Email (${email}) Shortly.`,
        confirmButtonText: 'OK'
      });
    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'Something Went Wrong',
        confirmButtonText: 'OK'
      });
    }
  } catch (error) {
    console.error('Error Sending Email:', error);
  }
};
/* ----------------------------------------------------- HTML ------------------------------------------------------------------------------- */
return (
  <div className='veri__main'>
    <h2>Verifying your account...</h2>
  </div>
);
}

export default Verification;
