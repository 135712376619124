import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../table.css';
import '../form.css';
import './Transaction.css';

function Transaction() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [selectedUserIDs, setSelectedUserIDs] = useState([]);  // Array to hold selected user IDs
    const [selectedTutor, setSelectedTutor] = useState("");
    const [transactions, setTransactions] = useState([]);
    const [tutors, setTutors] = useState([]);

/* ----------------------------------------------------- FETCH TUTORS ------------------------------------------------------------------------------- */
    useEffect(() => {
        const fetchTutors = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/Admin/getAllTutors`,{ 
                    headers: {
                      'Authorization': `Bearer ${token}`,
                      'ngrok-skip-browser-warning': 'true'
                    }
                  });
                if (response.status === 200) {
                    setTutors(response.data);
                } else {
                    console.log('Failed to fetch tutors:', response.error);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // Session expired, handle unauthorized case
                    Swal.fire({
                        icon: 'warning',
                        title: 'Session Expired',
                        text: 'Your session has expired. Please log out and log back in.',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        // Clear the token and redirect to login
                        localStorage.removeItem('token');
                        window.location.href = '/signin';
                    });
                }
                else{
                    console.log('Error fetching tutors:', error);
                }
            }
        };
        fetchTutors();
    }, [apiUrl, token]);
/* ----------------------------------------------------- FETCH TRANSACTIONS ------------------------------------------------------------------------------- */
    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/Admin/getTransactions`, { 
                    headers: {
                      'Authorization': `Bearer ${token}`,
                      'ngrok-skip-browser-warning': 'true'
                    }
                  });
                if (response.status === 200) {
                    setTransactions(response.data);
                } else {
                    console.log('Failed to fetch transactions:', response.error);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // Session expired, handle unauthorized case
                    Swal.fire({
                        icon: 'warning',
                        title: 'Session Expired',
                        text: 'Your session has expired. Please log out and log back in.',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        // Clear the token and redirect to login
                        localStorage.removeItem('token');
                        window.location.href = '/signin';
                    });
                }
                else{
                    console.log('Error fetching transactions:', error);
                }
            }
        };
        fetchTransactions();
    }, [apiUrl, token]);
/* ----------------------------------------------------- HANDLE EMAIL SELECT -------------------------------------------------------------------------- */
    const handleSelect = (email, userid) => {
        if (!selectedEmails.includes(email)) {
            setSelectedEmails([...selectedEmails, email]);
            setSelectedUserIDs([...selectedUserIDs, userid]);
        }
    };
/* ----------------------------------------------------- HANDLE REMOVE LAST EMAIL -------------------------------------------------------------------- */
    const handleRemoveLastEmail = () => {
        if (selectedEmails.length > 0) {
            const updatedEmails = [...selectedEmails];
            const updatedUserIDs = [...selectedUserIDs];

            updatedEmails.pop();
            updatedUserIDs.pop();

            setSelectedEmails(updatedEmails);
            setSelectedUserIDs(updatedUserIDs);
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'No emails to remove!',
            });
        }
    };
/* ----------------------------------------------------- HANDLE ADD USER TO TUTOR --------------------------------------------------------------------- */
    const handleAddAll = async () => {
        if (selectedEmails.length > 0 && selectedTutor) {
            try {
                const tutorID = parseInt(tutors.find(tutor => (tutor.tutor_FN + " " + tutor.tutor_LN) === selectedTutor).tutor_ID);

                // Create the data object to send to the backend
                const data = {
                    TutorID: tutorID,
                    UserID: selectedUserIDs,  // Send the user IDs
                };

                const response = await axios.post(`${apiUrl}/api/Admin/AddUserTutor`, 
                    data,
                    { 
                        headers: {
                          'Authorization': `Bearer ${token}`
                        }
                    }
                );

                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Users Added',
                        text: `Users successfully added to ${selectedTutor}.`,
                    }).then(() => {
                        // Clear the selected tutor and emails after successful addition
                        setSelectedTutor("");
                        setSelectedEmails([]);
                        setSelectedUserIDs([]);  // Clear the selected user IDs
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: 'Failed to add users to the tutor.',
                    });
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An error occurred while adding users to the tutor.',
                });
                console.log('Error adding users to tutor:', error);
            }
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Please select a tutor and at least one email.',
            });
        }
    };

/* ----------------------------------------------------- HTML --------------------------------------------------------------------------------------- */
    return (
        <div className='transaction__main'>
            <div className="main-table-container">
                <h2>Transaction List</h2>
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th># ID</th>
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>Country</th>
                            <th>Course Name</th>
                            <th>Date</th>
                            <th>Select</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((transaction) => (
                            <tr key={transaction.user_ID}>
                                <td>{transaction.transaction_ID}</td>
                                <td>{transaction.user_FullName}</td>
                                <td>{transaction.user_Email}</td>
                                <td>{transaction.user_Country}</td>
                                <td>{transaction.course_Name}</td>
                                <td>{transaction.date}</td>
                                <td>
                                    <button 
                                        className='table-select-button' 
                                        onClick={() => handleSelect(transaction.user_Email, transaction.user_ID)}
                                    >
                                        Select
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="transaction-form-container">
                <h2>Add User To Tutor</h2>
                <form className='transaction-form'>
                    <div className="transaction-form-group">
                        <label htmlFor="tutor-select">Select Tutor</label>
                        <select
                            id="tutor-select"
                            name="tutor-select"
                            className="transaction-styled-select"
                            value={selectedTutor}
                            onChange={(e) => setSelectedTutor(e.target.value)}
                        >
                            <option value="">Select a Tutor</option>
                            {tutors.map((tutor) => (
                                <option key={tutor.tutor_ID} value={tutor.tutor_FN + " " + tutor.tutor_LN}>
                                    {tutor.tutor_FN + " " + tutor.tutor_LN}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="transaction-form-group">
                        <label htmlFor="selected-emails">Selected Emails</label>
                        <textarea
                            id="selected-emails"
                            name="selected-emails"
                            rows="5"
                            cols="50"
                            className="transaction-selected-emails"
                            value={selectedEmails.join("\n")}
                            readOnly
                        />
                    </div>
                    <div className="transaction-form-buttons">
                        <button
                            type="button"
                            className="transaction-form-add-button"
                            onClick={handleAddAll}
                        >
                            Add Users to Tutor
                        </button>
                        <button
                            type="button"
                            className="transaction-form-remove-button"
                            onClick={handleRemoveLastEmail}
                        >
                            Remove Last Email
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Transaction;
