import {React, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import './Course.css';
import axios from 'axios';

function Course() {
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [courses, setCourses] = useState([]);
    const courseDescriptions = [
        {
            week: "Week 1 – Speaking",
            content: "This part of the course familiarises students with the speaking section of the PTE exam. PTE exam exercises such as Personal Introduction, Read Aloud, Repeat Sentence, Image Description and Re-Tell Lecture will all be addressed in this section. This section of the course will run for one week and include 3 sessions inclusive of exam relevant tips and tricks for time management."
        },
        {
            week: "Week 2 – Writing",
            content: "This part of the course familiarises students with the writing section of the PTE exam. PTE exam exercises such as Short-Answer Questions, Written Text Summary and Essay will all be addressed in this section. This section of the course will run for one week and include 3 sessions inclusive of exam relevant tips and tricks for time management."
        },
        {
            week: "Week 3 – Reading",
            content: "This part of the course familiarises students with the reading section of the PTE exam. PTE exam exercises such as Fill-In The Blanks, Multiple Choice-Multiple Answer questions, Paragraph Re-Ordering and Multiple Choice-Single Answer will all be addressed in this section. This section of the course will run for one week and include 3 sessions inclusive of exam relevant tips and tricks for time management."
        },
        {
            week: "Week 4 – Listening",
            content: "This part of the course familiarises students with the listening section of the PTE exam. PTE exam exercises such as Summary Of Spoken Text, Summary Highlights, Missing Words Selection and Incorrect Words Highlighting will all be addressed in this section. This section of the course will run for one week and include 3 sessions inclusive of exam relevant tips and tricks for time management."
        }
    ];
/* ----------------------------------------------------- DISPLAY COURSES ------------------------------------------------------------------------------- */
    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/Course/getCourses`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
    
                // Check if the response status is 200
                if (response.status === 200) {
                    setCourses(response.data);
                } else {
                    console.error('Error fetching courses:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };
    
        fetchCourses();
    }, [apiUrl]);
/* ----------------------------------------------------- VIEW COURSE ------------------------------------------------------------------------- */
    const handleViewCourse = (id, name, smalldesc, desc, price) =>{
        sessionStorage.setItem("course_ID", id);
        sessionStorage.setItem("course_Name", name);
        sessionStorage.setItem("course_SmallDesc", smalldesc);
        sessionStorage.setItem("course_Desc", desc);
        sessionStorage.setItem("course_Price", price);
        navigate("/course-details");
    }
/* ----------------------------------------------------- HTML ------------------------------------------------------------------------------- */
    return (
        <section>
            <div className='course__main'>
                <h1 className='course__title'>Our Courses</h1>
                <p className='course__intro'>
                    Kempton Academy’s PTE course is offered on a monthly semester basis. The course,
                    which runs for four weeks, covers all essential PTE exam requirements. A brief course
                    breakdown is presented below. (Please note that this course breakdown is for illustrative
                    purposes only, a final course schedule will be presented to you upon enrolment)
                </p>
                {courseDescriptions.map((desc, index) => (
                    <div key={index}>
                        <h1 className='course__intro__week'>{desc.week}</h1>
                        <p className='course__intro__para'>{desc.content}</p>
                    </div>
                ))}
                <div className='course__item__container'>
                    {courses.map((course) => (
                        <div className='course__item' key={course.course_ID}>
                            <h1 className='course__item__header'>{course.course_Name}</h1>
                            <p className='course__item__text'>{course.course_SmallDesc}</p>
                            <div className='course__seperator'></div>
                            <p className='course__item__text'>Price</p>
                            <p className='course__item__text'>${course.course_Price}</p>
                            <button 
                            className='course__btn' 
                            onClick={() => handleViewCourse(course.course_ID, course.course_Name, course.course_SmallDesc, course.course_Desc, course.course_Price)}
                            >View Course</button>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Course;
