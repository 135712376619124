import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../table.css';
import '../form.css';
import './Timetable.css';

function Timetable() {
const apiUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');
const [tutors, setTutors] = useState([]);
const [users, setUsers] = useState([]);
const [selectedTutor, setSelectedTutor] = useState('');
const [subject, setSubject] = useState(''); // Subject field
const [message, setMessage] = useState(''); // Message field
const [attachments, setAttachments] = useState([]); // Attachments state
const [loading, setLoading] = useState(false); // Loading state for button animation

/* ----------------------------------------------------- FETCH TUTORS ------------------------------------------------------------------------------- */
useEffect(() => {
    const fetchTutors = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/Admin/getAllTutors`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            if (response.status === 200) {
                setTutors(response.data);
            } else {
                console.log('Failed to fetch tutors:', response.error);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Session expired, handle unauthorized case
                Swal.fire({
                    icon: 'warning',
                    title: 'Session Expired',
                    text: 'Your session has expired. Please log out and log back in.',
                    confirmButtonText: 'OK'
                }).then(() => {
                    // Clear the token and redirect to login
                    localStorage.removeItem('token');
                    window.location.href = '/signin';
                });
            }
            else{
                console.log('Error fetching tutors:', error);
            }
        }
    };
    fetchTutors();
}, [apiUrl, token]);

/* ----------------------------------------------------- FETCH USERS BASED ON TUTOR ID -------------------------------------------------------------- */
const fetchUsers = async (tutorID) => {
    try {
        const response = await axios.get(`${apiUrl}/api/Admin/getUserTutor/${tutorID}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'ngrok-skip-browser-warning': 'true'
            }
        });
        if (response.status === 200) {
            setUsers(response.data);
        } else if (response.status === 404) {
            console.log('Failed to fetch users: No Users Found');
        }
        else {
            console.log('Error fetching users:', response.statusText);
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Session expired, handle unauthorized case
            Swal.fire({
              icon: 'warning',
              title: 'Session Expired',
              text: 'Your session has expired. Please log out and log back in.',
              confirmButtonText: 'OK'
            }).then(() => {
              // Clear the token and redirect to login
              localStorage.removeItem('token');
              window.location.href = '/signin';
            });
        }
        else{
            console.log('Error fetching users:', error);
        }
    }
};

/* ----------------------------------------------------- HANDLE TUTOR SELECTION -------------------------------------------------------------------- */
const handleTutorChange = (e) => {
    const tutorID = e.target.value;
    setSelectedTutor(tutorID); // Set the selected tutor ID
    fetchUsers(tutorID); // Fetch users related to this tutor
};

/* ----------------------------------------------------- HANDLE ATTACHMENTS ----------------------------------------------------------------------- */
const handleAttachmentsChange = (e) => {
    setAttachments(e.target.files);  // Store selected files in state
};

/* ----------------------------------------------------- HANDLE EMAIL ALL USERS -------------------------------------------------------------------- */
const handleEmailAll = async () => {
    if (subject && message && users.length >= 1) {  // Ensure users are greater than 0
        setLoading(true); // Start loading
        try {
            const formData = new FormData();
            const emailList = users.map(user => user.user_Email);  // Collect user emails

            formData.append('subject', subject);
            formData.append('message', message);

            // Append each email individually (FormData does not handle arrays directly)
            emailList.forEach(email => {
                formData.append('emails', email);
            });

            // Check if attachments exist
            if (attachments && attachments.length > 0) {
                // Append each selected file to the form data
                for (let i = 0; i < attachments.length; i++) {
                    formData.append('attachments', attachments[i]);
                }

                // If attachments exist, send to the endpoint for sending emails with attachments
                const response = await axios.post(`${apiUrl}/api/Admin/sendEmailsWithAttachments`, 
                    formData,
                    { 
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'  // Required for file uploads
                        }
                    }
                );

                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Emails Sent',
                        text: 'Emails with attachments have been successfully sent to all users.'
                    });
                    setMessage("");
                    setSubject("");
                    setAttachments([]);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: 'Failed to send emails with attachments to users.'
                    });
                }

            } else {
                // If no attachments, send to the endpoint for emails without attachments
                const response = await axios.post(`${apiUrl}/api/Admin/sendEmailsWithoutAttachments`, 
                    formData,
                    { 
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'  // For form data without attachments as well
                        }
                    }
                );

                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Emails Sent',
                        text: 'Emails without attachments have been successfully sent to all users.'
                    });
                    setMessage("");
                    setSubject("");
                    setAttachments([]);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: 'Failed to send emails without attachments to users.'
                    });
                }
            }

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while sending emails.'
            });
            console.log('Error sending emails:', error);
        } 
        finally {
            setLoading(false); // Stop loading
        }
    } else {
        Swal.fire({
            icon: 'warning',
            title: 'Incomplete',
            text: 'Please fill out the subject and message fields, and ensure there are users to send emails to.'
        });
    }
};
/* ----------------------------------------------------- HTML -------------------------------------------------------------------- */
return (
    <div className='userTutor__main'>
        <div className="main-table-container">
            <h2>Tutor Users List</h2>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Country</th>
                        <th>City</th>
                        <th>Course Name</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.user_Email}>
                            <td>{user.user_FullName}</td>
                            <td>{user.user_Email}</td>
                            <td>{user.user_Country}</td>
                            <td>{user.user_City}</td>
                            <td>{user.course_Name}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

        <div className="form-container">
            <h2>Email All Users</h2>
            <form className='form'>
                <div className="form-group">
                    <label htmlFor="tutor-select">Select Tutor</label>
                    <select
                        id="tutor-select"
                        name="tutor-select"
                        className="styled-select"
                        value={selectedTutor}
                        onChange={handleTutorChange} // Call handleTutorChange on select
                    >
                        <option value="">Select a Tutor</option>
                        {tutors.map((tutor) => (
                            <option key={tutor.tutor_ID} value={tutor.tutor_ID}>
                                {tutor.tutor_FN + " " + tutor.tutor_LN}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="subject">Subject</label>
                    <input
                        type="text"
                        id="subject"
                        name="subject"
                        className="user-input"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        rows="5"
                        cols="50"
                        className="user-input"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="attachments">Attachments (Optional):</label>
                    <input
                        type="file"
                        id="attachments"
                        name="attachments"
                        multiple
                        onChange={handleAttachmentsChange}
                    />
                </div>

                <div className="form-email-buttons">
                    <button
                        type="button"
                        className={`email-all-button ${loading ? 'loading' : ''}`}
                        onClick={handleEmailAll}
                        disabled={loading}
                    >
                        {loading ? 'Sending...' : 'Email All'}
                    </button>
                </div>
            </form>
        </div>
    </div>
);
}

export default Timetable;
