import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../table.css';
import '../form.css';
import './adminCourse.css';

function AdminCourse() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');
  const [courses, setCourses] = useState([]);
  const [formData, setFormData] = useState({
    course_ID: '',
    course_Name: '',
    course_SmallDesc: '',
    course_Desc: '',
    course_Price: ''
  });

/* ----------------------------------------------------- FETCH COURSES ----------------------------------------------------------------------------------- */
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/Course/getCourses`, {
          headers: {
            'ngrok-skip-browser-warning': 'true'
          }
        });
        if (response.status === 200) {
          setCourses(response.data);
        } else {
          console.log('Failed to fetch courses:', response.statusText);
        }
      } catch (error) {
        console.log('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, [apiUrl]);
/* ----------------------------------------------------- SET FIELDS TO DATA ----------------------------------------------------------------------------------- */
  const handleSetData = (tutor) => {
    setFormData(tutor);
  };
/* ----------------------------------------------------- HANDLE INPUT CHANGE ----------------------------------------------------------------------------------- */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
/* ----------------------------------------------------- ADD COURSE ----------------------------------------------------------------------------------- */
  const handleAddCourse = async () => {
    if (!formData.course_Name || !formData.course_SmallDesc || !formData.course_Desc || !formData.course_Price) {
      Swal.fire('Warning', 'Please fill in all required fields.', 'warning');
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/api/Admin/AddCourse`, 
        {  
          course_Name: formData.course_Name,
          course_SmallDesc: formData.course_SmallDesc,
          course_Desc: formData.course_Desc,
          course_Price: parseFloat(formData.course_Price)
        },
        { 
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Course Added",
          showConfirmButton: false,
          timer: 3000
        });
        setTimeout(() => { window.location.reload(); }, 3000);
      } else {
        Swal.fire('Error', 'Failed to add course', 'error');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Session expired, handle unauthorized case
        Swal.fire({
          icon: 'warning',
          title: 'Session Expired',
          text: 'Your session has expired. Please log out and log back in.',
          confirmButtonText: 'OK'
        }).then(() => {
          // Clear the token and redirect to login
          localStorage.removeItem('token');
          window.location.href = '/signin';
        });
      }
      else{
        Swal.fire('Error', 'An error occurred while adding the course', 'error');
      }
    }
  };
 /* ----------------------------------------------------- EDIT COURSE ----------------------------------------------------------------------------------- */
  const handleEditCourse = async () => {
    if (!formData.course_Name || !formData.course_SmallDesc || !formData.course_Desc || !formData.course_Price) {
      Swal.fire('Warning', 'Please fill in all required fields.', 'warning');
      return;
    }

    try {
      const response = await axios.put(`${apiUrl}/api/Admin/EditCourse`, 
        formData,
        { 
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Course Updated",
          showConfirmButton: false,
          timer: 2000
        });
        setTimeout(() => { window.location.reload(); }, 2000);
      } else {
        Swal.fire('Error', 'Failed to edit course', 'error');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Session expired, handle unauthorized case
        Swal.fire({
          icon: 'warning',
          title: 'Session Expired',
          text: 'Your session has expired. Please log out and log back in.',
          confirmButtonText: 'OK'
        }).then(() => {
          // Clear the token and redirect to login
          localStorage.removeItem('token');
          window.location.href = '/signin';
        });
      }
      else{
        Swal.fire('Error', 'An error occurred while editing the course', 'error');
      }
    }
  };
/* ----------------------------------------------------- DELETE COURSE ----------------------------------------------------------------------------------- */
  const handleDeleteCourse = async () => {
    if (!formData.course_ID) {
      Swal.fire('Warning', 'Please Select A Course To Delete', 'warning');
      return;
    }

    try {
      const response = await axios.delete(`${apiUrl}/api/Admin/DeleteCourse/${parseInt(formData.course_ID)}`,{ 
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Course Deleted",
          showConfirmButton: false,
          timer: 2000
        });
        setTimeout(() => { window.location.reload(); }, 2000);
      } else {
        Swal.fire('Error', 'Failed to delete course', 'error');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Session expired, handle unauthorized case
        Swal.fire({
          icon: 'warning',
          title: 'Session Expired',
          text: 'Your session has expired. Please log out and log back in.',
          confirmButtonText: 'OK'
        }).then(() => {
          // Clear the token and redirect to login
          localStorage.removeItem('token');
          window.location.href = '/signin';
        });
      }
      else{
        Swal.fire('Error', 'An error occurred while deleting the course', 'error');
      }
    }
  };
/* ----------------------------------------------------- HTML ----------------------------------------------------------------------------------- */
  return (
    <div className='admin-course__main'>
        <div className="main-table-container">
            <h2>Course List</h2>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th># ID</th>
                        <th>Name</th>
                        <th>Small Desc</th>
                        <th>Description</th>
                        <th>Price</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {courses.map((course, index) => (
                        <tr key={index}>
                            <td>{course.course_ID}</td>
                            <td>{course.course_Name}</td>
                            <td>{course.course_SmallDesc}</td>
                            <td>{course.course_Desc}</td>
                            <td>${course.course_Price}</td>
                            <td>
                                <button 
                                    className='table-select-button' 
                                    onClick={() => handleSetData(course)}
                                >
                                    Select
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

        <div className="form-container">
          <h2>Customize Course</h2>
          <form className='form'>
            <div className="form-group">
              <label htmlFor="course-id">Course ID</label>
              <input 
                type="text" 
                id="course-id" 
                name="course_ID" 
                className="user-input" 
                readOnly 
                style={{ backgroundColor: "#f0f0f0" }} 
                value={formData.course_ID} 
              />
            </div>
            <div className="form-group">
              <label htmlFor="course-name">Course Name</label>
              <input 
                type="text" 
                id="course-name" 
                name="course_Name" 
                className='user-input' 
                required 
                value={formData.course_Name} 
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="course-small-desc">Course Small Desc</label>
              <input 
                type="text" 
                id="course-small-desc" 
                name="course_SmallDesc" 
                className='user-input' 
                required 
                value={formData.course_SmallDesc} 
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="course-desc">Course Desc (Separate the bullet points with a [,])</label>
              <input 
                type="text" 
                id="course-desc" 
                name="course_Desc" 
                className='user-input' 
                required 
                value={formData.course_Desc} 
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="course-price">Course Price</label>
              <input 
                type="text" 
                id="course-price" 
                name="course_Price" 
                className='user-input' 
                required 
                value={formData.course_Price} 
                onChange={handleChange}
              />
            </div>
          </form>
          <div className='form__btn'>
              <button type="button" className="form-add-button" onClick={handleAddCourse}>Add Course</button>
              <button type="button" className="form-edit-button" onClick={handleEditCourse}>Edit Course</button>
              <button type="button" className="form-delete-button" onClick={handleDeleteCourse}>Delete Course</button>
          </div>
        </div>
    </div>
  );
}

export default AdminCourse;
