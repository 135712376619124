import React from 'react'
import ABT from '../../assets/aboutus.jpg'
import './About.css'
import { useNavigate } from 'react-router-dom';

function About() {
  const navigate = useNavigate();

  return (
    <section id="about">
        <div className='about__main'>
             <img src={ABT} alt='' className='about__img'/>
             <div className='about__content'>
                <h1 className='about__h1'>ABOUT <h1 className='about__h2'>US</h1></h1>
                <p className='about__para'>At Kempton Academy, we understand that mastering the Pearson Test of English (PTE)
                Academic is crucial step towards achieving your academic and career goals. Whether
                you’re aiming to study abroad, secure a job, or meet visa requirements, a high PTE score
                can open doors to endless opportunities.</p>

                <p className='about__para'>Kempton was founded with the purpose of helping individuals overcome the language
                requirement barrier often associated with enrolling into top universities or obtaining
                immigration. Our mission is simple: to provide you with the knowledge, skills, and
                confidence you need to achieve your desired PTE score. We believe that every student
                has the potential to succeed, and our courses are crafted to bring out the best in you. </p>

                <button className='about__btn' onClick={() => navigate("/courses")}>LEARN MORE</button>
             </div>
        </div>
    </section>
  )
}

export default About