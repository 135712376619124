import React, { useState } from 'react';
import './Sidebar.css';
import { NavLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Users from '../../../assets/users.png';
import Tutors from '../../../assets/tutor.png';
import Courses from '../../../assets/book.png';
import Transactions from '../../../assets/transaction.png';
import UsersTo from '../../../assets/userstotutors.png';
import Logout from '../../../assets/logout.png';
import Control from '../../../assets/controls.png';

function Sidebar({ children }) {
    const navigate = useNavigate(); // Properly initialize the navigate variable
    const [currMenu, setCurrMenu] = useState("");
    
    const menuItems = [
        {
            path: "/admin-panel",
            name: "View Users",
            icon: Users
        },
        {
            path: "/admin/view-tutors",
            name: "View Tutors",
            icon: Tutors
        },
        {
            path: "/admin/view-courses",
            name: "View Courses",
            icon: Courses
        },
        {
            path: "/admin/view-transactions",
            name: "View Transactions",
            icon: Transactions
        },
        {
            path: "/admin/view-users-tutors",
            name: "View Users To Tutors",
            icon: UsersTo
        },
        {
            path: "/admin/view-controls",
            name: "Controls",
            icon: Control
        }
    ]; 

    const logoutItem = {
        path: "/signin",
        name: "Logout",
        icon: Logout,
    };

    const handleLogout = () => {
      // Handle logout
      localStorage.removeItem('user_id');
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      navigate('/');
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "You Have Been Logged Out",
        showConfirmButton: false,
        timer: 1500
      });
    };

    return (
        <div className="layout">
            <div className='sidebar'>
                <div className="sidebar__content">
                    <div className='sidebar__header'>
                        <h1 className="sidebar__logo">Admin Panel</h1>
                    </div>
                    <div className='sidebar__menu'>
                        {menuItems.map((item, index) => (
                            <NavLink 
                                to={item.path} 
                                key={index} 
                                className={currMenu === item.name ? "sidebar__menu-item sidebar__menu-item--active" : "sidebar__menu-item"} 
                                onClick={() => setCurrMenu(item.name)}
                            >
                                <img src={item.icon} alt={item.name} className='sidebar__menu-icon' />
                                <span className="sidebar__menu-text">{item.name}</span>
                            </NavLink>
                        ))}
                    </div>
                    <div className="sidebar__logout">
                        <NavLink to={logoutItem.path} className="sidebar__menu-item" onClick={handleLogout}>
                            <img src={logoutItem.icon} alt={logoutItem.name} className='sidebar__logout-icon'/>
                            <span className="sidebar__menu-text">{logoutItem.name}</span>
                        </NavLink>
                    </div>
                </div>
            </div>
            <main className='main-content'>
                {children}
            </main>
        </div>
    );
}

export default Sidebar;
