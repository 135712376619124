import React from 'react';
import Sidebar from './Sidebar/Sidebar';

function AdminLayout({ children }) {
  return (
    <div>
      <Sidebar>{children}</Sidebar>
    </div>
  );
}

export default AdminLayout;
