import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
import '../table.css';
import './Control.css';
import '../form.css';

function Control() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');
  const [control, setControl] = useState([]);

/* ----------------------------------------------------- FETCH BUTTON STATUS ------------------------------------------------------------------------------- */
useEffect(() => {
  const fetchStatus = async () => {
      try {
          const response = await axios.get(`${apiUrl}/api/User/getControlStatus`,{ 
              headers: {
                'ngrok-skip-browser-warning': 'true'
              }
            });
          if (response.status === 200) {
              setControl(response.data);
          } else {
              console.log('Failed to fetch status:', response.error);
          }
      } catch (error) {
          console.log('Error fetching status:', error);
      }
  };
  fetchStatus();
}, [apiUrl]);
/* ----------------------------------------------------- UPDATE BUTTON STATUS ------------------------------------------------------------------------------- */
const UpdateStatus = async (status) => {
  const updatedStatus = status === 1 ? 0 : 1;  // Toggle status

  try {
    const response = await axios.put(`${apiUrl}/api/Admin/updateControlStatus`,
       {
        Control: control.control,
        Status: updatedStatus  // Use the toggled status here
       },
       { 
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
    if (response.status === 200) {
      Swal.fire('Button Updated', 'Success');
      window.location.reload()
    } else {
        console.log('Failed to update status:', response.error);
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Session expired, handle unauthorized case
      Swal.fire({
        icon: 'warning',
        title: 'Session Expired',
        text: 'Your session has expired. Please log out and log back in.',
        confirmButtonText: 'OK'
      }).then(() => {
        // Clear the token and redirect to login
        localStorage.removeItem('token');
        window.location.href = '/signin';
      });
    } else {
      Swal.fire('Something Went Wrong', 'Error');
    }
  }
};
/* ----------------------------------------------------- FETCH BUTTON STATUS ------------------------------------------------------------------------------- */

  return (
    <div className='control__main'>
      <div className="form-container">
      <h2>Controls:</h2>
        <div className="form-group">
          <h1>PurchaseButton</h1>
          <button
            onClick={() => UpdateStatus(control.status)}
            className={`status__button ${control.status === 1 ? 'status__button--enabled' : 'status__button--disabled'}`}
          >
            {control.status === 1 ? 'Disable Button' : 'Enable Button'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Control