import React from 'react';
import './TermsPages.css';

function Refund() {
  const refundItems = [
    {
      header: '1. General Policy',
      list: [
        {
          subheading: '1.1 No Refunds',
          text: 'All purchases made for courses offered by Kempton Academy are final. We do not offer refunds once a course has been purchased.',
        },
        {
          subheading: '1.2 Acknowledgment',
          text: 'By purchasing a course, you acknowledge and agree that you have read and understood this Refund Policy, and that no refund will be issued under any circumstances.',
        },
      ],
    },
    {
      header: '2. Course Cancellations',
      list: [
        {
          subheading: '2.1 Cancellations by Kempton Academy',
          text: 'In the unlikely event that Kempton Academy cancels a course, students will be offered the option to enrol in a different course or receive a full refund.',
        },
        {
          subheading: '2.2 Rescheduling',
          text: 'If a course session is rescheduled due to unforeseen circumstances, Kempton Academy will notify students as soon as possible and provide alternative session dates. No refunds will be issued for rescheduled sessions.',
        },
      ],
    },
    {
      header: '3. Compensation for Delayed Sessions',
      list: [
        {
          subheading: '3.1 Tutor Delays',
          text: 'If a tutor is more than ten (10) minutes late for a scheduled session, students will be compensated with an additional session at no extra cost.',
        },
      ],
    },
    {
      header: '4. Disputes',
      list: [
        {
          subheading: '4.1 Dispute Resolution',
          text: 'If you have any issues or concerns regarding your purchase or our services, please contact us at our Contact Us Page. We will work with you to resolve any disputes amicably.',
        },
      ],
    },
  ];

  return (
    <div className='termsc__main'>
      <div className='termsc__container'>
        <h1 className='termsc__title'>Refund Policy</h1>
        {refundItems.map((item, index) => (
          <div key={index}>
            <h2 className='termsc__header'>{item.header}</h2>
            <ul className='termsc__list'>
              {item.list.map((listItem, subIndex) => (
                <li key={subIndex} className='termsc__item'>
                  <p className='termsc__subheading'>{listItem.subheading}</p>
                  <p>{listItem.text}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Refund;
