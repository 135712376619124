import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CourseDeatils.css';
import axios from 'axios';
import Swal from 'sweetalert2';

const apiURL = process.env.REACT_APP_API_URL;

function CourseDetails() {
  const navigate = useNavigate();
  const loggedIN = localStorage.getItem('user_id');
  const email = localStorage.getItem("user_email");
  const verified = localStorage.getItem("verified");
  const course_Name = sessionStorage.getItem("course_Name");
  const course_SmallDesc = sessionStorage.getItem("course_SmallDesc");
  const course_Desc = sessionStorage.getItem("course_Desc");
  const course_Price = sessionStorage.getItem("course_Price");
  const courseFeatures = course_Desc ? course_Desc.split(',') : [];
  const [isLoading, setIsLoading] = useState(false);
  const [control, setControl] = useState({}); // Updated to store control status

/* ----------------------------------------------------- HANDLE PURCHASE ------------------------------------------------------------------------------- */
const handlePurchase = async () => {
  setIsLoading(true);
  if (!loggedIN) {
    Swal.fire({
      icon: 'warning',
      title: 'You must be logged in',
      text: 'Please log in to proceed with the purchase.',
      confirmButtonText: 'Login',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/signin');
      }
    });
    setIsLoading(false);
    return;
  }
  else if(verified === "0"){
    Swal.fire({
      title: 'Email Verification',
      text: 'You need to verify your email. Would you like to resend the verification link?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Resend Verification Link',
      cancelButtonText: 'Close',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        sendVerificationLink(); 
      }
    });
    setIsLoading(false);
    return;
  }

  try {
    const response = await axios.post(`${apiURL}/api/User/sendBankDetails/${email}/${loggedIN}`);
    if(response.status === 200){
      Swal.fire({
        icon: 'success',
        title: 'An Email Has Been Sent To You With Instructions',
        confirmButtonText: 'OK'
      });
      setIsLoading(false);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Something Went Wrong',
        confirmButtonText: 'OK'
      });
      setIsLoading(false);
    }
  } catch (error) {
    console.error('Error Sending Email:', error);
    setIsLoading(false);
  }
};

/* ----------------------------------------------------- HANDLE SEND VERIFICATION LINK ------------------------------------------------------ */
const sendVerificationLink = async () => {
  try {
    const response = await axios.post(`${apiURL}/api/User/sendverificationlink/${email}`);
    if(response.status === 200){
      Swal.fire({
        icon: 'success',
        title: 'A New Verification Link Has Been Sent To Your Email',
        confirmButtonText: 'OK'
      });
      setIsLoading(false);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Something Went Wrong',
        confirmButtonText: 'OK'
      });
      setIsLoading(false);
    }
  } catch (error) {
    console.error('Error Sending Email:', error);
    setIsLoading(false);
  }
};

/* ----------------------------------------------------- FETCH BUTTON STATUS ------------------------------------------------------------------------------- */
useEffect(() => {
  const fetchStatus = async () => {
    try {
      const response = await axios.get(`${apiURL}/api/User/getControlStatus`, {
        headers: {
          'ngrok-skip-browser-warning': 'true'
        }
      });
      if (response.status === 200) {
        setControl(response.data); // Store the status in control
        console.log(response.data);
      } else {
        console.log('Failed to fetch status:', response.error);
      }
    } catch (error) {
      console.log('Error fetching status:', error);
    }
  };
  fetchStatus();
}, []);

/* ----------------------------------------------------- HTML ------------------------------------------------------------------------------- */
  return (
    <div className='courseDetails__main'>
      <div className='courseDetails__content'>
        <h1 className='courseDetails__title'>{course_Name}</h1>
        <p className='courseDetails__para'>{course_SmallDesc}</p>
        <div className='courseDetails__pricing'>
          <div className='div1'>Price</div>
          <div className='div2'>${course_Price}</div>
        </div>
        <h2 className='courseDetails__descHeader'>Course Description</h2>
        <ul className='courseDetails__desc'>
          {courseFeatures.map((feature, index) => (
            <li className='divv' key={index}>
              {feature.trim()}
            </li>
          ))}
        </ul>
        <button
          className={`courseDetails__btn ${control.status === 0 ? 'courseDetails__btn:disabled' : ''}`}
          onClick={handlePurchase}
          disabled={control.status === 0 || isLoading}
        >
          {control.status === 0 ? 'Button Disabled' : isLoading ? 'Loading...' : 'Purchase'}
        </button>
      </div>
    </div>
  );
}

export default CourseDetails;
