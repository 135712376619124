import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Terms.css';

function Terms() {
  const navigate = useNavigate();

  const termsItems = [
    {
      header: 'Terms',
      para: 'Welcome to Kempton Academy. By accessing and using our website, registering for our courses, and utilizing our services, you agree to comply with and be bound by the following Terms and Conditions. Please read them carefully before making any purchase or using our services.',
      route: '/t&c'
    },
    {
      header: 'Privacy Policy',
      para: 'Kempton Academy values your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our website, register for courses, and interact with our services.',
      route: '/privacy-policy'
    },
    {
      header: 'Refund Policy',
      para: 'Kempton Academy is dedicated to ensuring your satisfaction with our courses and services. We are committed to providing clear and transparent guidelines to help you make informed decisions and understand your rights as a valued customer.',
      route: '/refund-policy'
    },
  ];

  const handleLearnMore = (route) => {
    navigate(route);
  };

  return (
    <div className='terms__main'>
      <h1 className='terms__title'>Terms Of Service</h1>
      <div className='terms__item__container'>
        {termsItems.map((item, index) => (
          <div className='terms__item' key={index}>
            <h1 className='terms__header'>{item.header}</h1>
            <p className='terms__para'>{item.para}</p>
            <button className='terms__btn' onClick={() => handleLearnMore(item.route)}>Learn More</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Terms;
