import React from 'react';
import './TermsPages.css';

const termsData = [
  {
    header: '2. Information We Collect',
    items: [
      {
        number: '2.1',
        text: 'Personal Information: When you register for a course, we collect personal information such as your name, email address, phone number, and payment details.',
      },
      {
        number: '2.2',
        text: 'Usage Data: We may collect information about how you access and use our website, including your IP address, browser type, and pages visited. This information helps us improve our services and user experience.',
      },
      {
        number: '2.3',
        text: 'Cookies: We use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small files stored on your device that allow us to remember your preferences and provide personalized content.',
      },
    ],
  },
  {
    header: '3. How We Use Your Information',
    items: [
      {
        number: '3.1',
        text: 'To Provide Services: We use your personal information to process your registration, manage your course schedule, and communicate with you regarding your course.',
      },
      {
        number: '3.2',
        text: 'Payment Processing: Your payment details are used to process transactions securely. We do not store full credit card information on our servers.',
      },
      {
        number: '3.3',
        text: 'Communication: We may use your contact information to send you important updates, course materials, and promotional offers. You can opt-out of marketing communications at any time.',
      },
      {
        number: '3.4',
        text: 'Improvement of Services: We use usage data to analyze trends, track user engagement, and improve the functionality of our website.',
      },
    ],
  },
  {
    header: '4. How We Protect Your Information',
    items: [
      {
        number: '4.1',
        text: 'Security Measures: We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, or disclosure. This includes encryption, secure servers, and access controls.',
      },
      {
        number: '4.2',
        text: 'Third-Party Services: We may share your information with trusted third-party service providers who assist us in operating our website, processing payments, and delivering services. These providers are required to maintain the confidentiality and security of your information.',
      },
    ],
  },
  {
    header: '5. Your Rights',
    items: [
      {
        number: '5.1',
        text: 'Access and Correction: You have the right to access and update your personal information at any time by logging into your account.',
      },
      {
        number: '5.2',
        text: 'Data Deletion: You can request the deletion of your personal information by contacting us at [info@kemptonacademy.com]. Please note that some information may be retained for legal or administrative purposes.',
      },
      {
        number: '5.3',
        text: 'Opt-Out: You can opt-out of receiving marketing communications by following the unsubscribe link in our emails or by contacting us directly.',
      },
    ],
  },
  {
    header: '6. Changes to This Privacy Policy',
    items: [
      {
        number: '6.1',
        text: 'Updates: We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.',
      },
      {
        number: '6.2',
        text: 'Continued Use: By continuing to use our services after any changes to this Privacy Policy, you agree to the revised terms.',
      },
    ],
  },
];

function TermsC() {
  return (
    <div className='termsc__main'>
      <div className='termsc__container'>
        <h1 className='termsc__title'>Terms & Conditions</h1>
        {termsData.map((section, index) => (
          <div key={index}>
            <h2 className='termsc__header'>{section.header}</h2>
            <ul className='termsc__list'>
              {section.items.map((item, idx) => (
                <li className='termsc__item' key={idx}>
                  <p className='termsc__subheading'>{item.number}</p>
                  <p>{item.text}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TermsC;
