import axios from 'axios';
import React, { useState } from 'react';
import './SignUp.css';
import Logo from '../../assets/logo.png';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { Link } from 'react-router-dom';

function SignUp() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState(null);
    const [city, setCity] = useState('');
    const [loading, setLoading] = useState(false);

    // Email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

const handleSignUp = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Ensure all fields are filled
    if (!firstName || !lastName || !email || !password || !confirmPassword || !phone || !country || !city) {
        Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Please fill all fields!',
        });
        setLoading(false);
        return;
    }

    // Validate email format
    if (!emailRegex.test(email)) {
        Swal.fire({
            icon: 'warning',
            title: 'Invalid Email',
            text: 'Please enter a valid email address (e.g. example@mail.com).',
        });
        setLoading(false);
        return;
    }

    // Password validation: at least 10 characters long and contains at least 1 number
    const passwordRegex = /^(?=.*\d).{10,}$/;
    if (!passwordRegex.test(password)) {
        Swal.fire({
            icon: 'warning',
            title: 'Password Requirements',
            text: 'Password must be at least 10 characters long and contain at least 1 number.',
        });
        setLoading(false);
        return;
    }

    if (password !== confirmPassword) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Passwords do not match!',
        });
        setLoading(false);
        return;
    }

    const formData = new FormData();
    formData.append("User_FN", firstName);
    formData.append("User_LN", lastName);
    formData.append("User_Email", email);
    formData.append("User_Password", confirmPassword);
    formData.append("User_Phone", phone);
    formData.append("User_Country", `${country.label}`);
    formData.append("User_City", city);

    try {
        const response = await axios.post(`${apiUrl}/api/User/Signup`, formData);

        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'Sign Up Successful',
                text: `Thank you for signing up! A verification email will be sent to ${email} shortly. Please Check your Spam Folder if you have not recieved any email.`,
                confirmButtonText: 'OK'
            });
            navigate("/signin");
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Sign Up Failed',
                text: "Something went wrong.",
                confirmButtonText: 'OK'
            });
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            Swal.fire({
                icon: 'error',
                title: 'Sign Up Failed',
                text: "There is an existing user with this email.",
                confirmButtonText: 'OK'
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Sign Up Failed',
                text: 'An error occurred while signing up. Please try again later.',
                confirmButtonText: 'OK'
            });
        }
    } finally {
        setLoading(false);
    }
};

    const countryOptions = countryList().getData();

    return (
        <div className="signup__container">
            <div className='signup__header'>
                <img src={Logo} alt="Kempton Academy Logo" className='signup__logo' />
                <h1 className='signup__title'>Kempton Academy</h1>
            </div>

            <form className="signup__form" onSubmit={handleSignUp}>
                <h2 className="signup__title">Sign Up</h2>

                <div className="signup__row">
                    <div className="signup__inputGroup">
                        <label htmlFor="firstName" className="signup__label">First Name <span className='star'>*</span></label>
                        <input
                            type="text"
                            id="firstName"
                            className="signup__input"
                            placeholder="Enter first name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>

                    <div className="signup__inputGroup">
                        <label htmlFor="lastName" className="signup__label">Last Name <span className='star'>*</span></label>
                        <input
                            type="text"
                            id="lastName"
                            className="signup__input"
                            placeholder="Enter last name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="signup__inputGroup">
                    <label htmlFor="email" className="signup__label">Email <span className='star'>*</span></label>
                    <input
                        id="email"
                        className="signup__input"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="signup__row">
                    <div className="signup__inputGroup">
                        <label htmlFor="password" className="signup__label">Password <span className='star'>*</span></label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            className="signup__input"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <div className="signup__inputGroup">
                        <label htmlFor="confirmPassword" className="signup__label">Confirm Password <span className='star'>*</span></label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="confirmPassword"
                            className="signup__input"
                            placeholder="Confirm password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                </div>

                <div className="signup__showPassword">
                    <input
                        type="checkbox"
                        id="showPassword"
                        className="signup__checkbox"
                        onChange={togglePasswordVisibility}
                    />
                    <label htmlFor="showPassword" className="signup__label">Show Password</label>
                </div>

                <div className="signup__row">
                    <div className="signup__inputGroup">
                        <label htmlFor="phone" className="signup__label">Phone <span className='star'>*</span></label>
                        <PhoneInput
                            country={'us'}
                            value={phone}
                            onChange={setPhone}
                            inputClass="signup__input"
                        />
                    </div>

                    <div className="signup__inputGroup">
                        <label htmlFor="country" className="signup__label">Country <span className='star'>*</span></label>
                        <Select
                            options={countryOptions}
                            value={country}
                            onChange={setCountry}
                            className="signup__input"
                        />
                    </div>
                </div>

                <div className="signup__inputGroup">
                    <label htmlFor="city" className="signup__label">City <span className='star'>*</span></label>
                    <input
                        type="text"
                        id="city"
                        className="signup__input"
                        placeholder="Enter your city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </div>

                <button type="submit" className={`signup__button ${loading ? 'loading' : ''}`} disabled={loading}>
                    {loading ? "Loading..." : "Submit"}
                </button>

                <p className="signup__agree1">
                    By Signing Up with Kempton Academy, You agree to our <Link to="/terms" className="signup__agree2"> Terms & Conditions</Link>
                </p>

                <div className="signup__separator"></div>
                <p className="signup__text">
                    Already Have An Account? <Link to="/signin" className="signup__link">Sign In!</Link>
                </p>
            </form>
        </div>
    );
}

export default SignUp;
