import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../table.css';
import './User.css';
import '../form.css';

function User() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');
  const [users, setUsers] = useState([]);
  const [selectedUserIDs, setSelectedUserIDs] = useState([]);
  const [userEmails, setUserEmails] = useState([]);
  const [subject, setSubject] = useState("");
  const [courseID, setCourseID] = useState("");
  const [message, setMessage] = useState("");
  const [attachments, setAttachments] = useState([]); // State to hold file attachments
  const [loading, setLoading] = useState(false);

  /* ----------------------------------------------------- DISPLAY USERS ------------------------------------------------------------------------------- */
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/Admin/getAllUsers`, { 
          headers: {
              'Authorization': `Bearer ${token}`,
              'ngrok-skip-browser-warning': 'true'
          }
        });
        if (response.status === 200) {
          setUsers(response.data);
        } else if (response.status === 404) {
          alert("No Users Found");
        } else {
          console.log(response.error);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Session expired, handle unauthorized case
          Swal.fire({
              icon: 'warning',
              title: 'Session Expired',
              text: 'Your session has expired. Please log out and log back in.',
              confirmButtonText: 'OK'
          }).then(() => {
              // Clear the token and redirect to login
              localStorage.removeItem('token');
              window.location.href = '/signin';
          });
      }
      else{
        console.error('Error fetching users:', error);
      }
      }
    };

    fetchUsers();
  }, [apiUrl, token]);

  /* ----------------------------------------------------- HANDLE SELECT ----------------------------------------------------------------------------- */
  const handleSelect = (email, userID) =>{
    if (!userEmails.includes(email)) {
      setUserEmails([...userEmails, email]);
      setSelectedUserIDs([...selectedUserIDs, userID]);
    }
  };

  const handleRemoveLastEmail = () => {
    if (userEmails.length > 0) {
        const updatedEmails = [...userEmails];
        const updatedUserIDs = [...selectedUserIDs];

        updatedEmails.pop();
        updatedUserIDs.pop();

        setUserEmails(updatedEmails);
        setSelectedUserIDs(updatedUserIDs);
    } else {
        Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'No emails to remove!',
        });
    }
  };

/* ----------------------------------------------------- HANDLE ADD USER TO TRANSACTION ------------------------------------------------------------ */
const handleAddUserToTransaction = async (e) => {
  e.preventDefault();
  setLoading(true);

  // Validation to check if users and courseID are entered
  if (userEmails.length === 0 || !courseID) {
    Swal.fire({
      icon: 'warning',
      title: 'Oops...',
      text: 'No Users Selected or No course ID entered',
    });
    setLoading(false); // Stop loading after Swal is triggered
    return; // Exit the function if validation fails
  }

  const data = {
    UserID: selectedUserIDs.map(id => parseInt(id)), // map each ID to an integer
    CourseID: parseInt(courseID),
  };

  try {
    const response = await axios.post(`${apiUrl}/api/Admin/AddUserTransaction`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        title: 'Users Added To Transactions',
      });
      setSelectedUserIDs([]);
      setUserEmails([]);
      setCourseID("");
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to add users to transactions.',
      });
    }
  } catch (error) {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while adding users to transactions.',
    });
  } finally {
    setLoading(false); // Stop loading animation
  }
};

  /* ----------------------------------------------------- HANDLE ATTACHMENTS ----------------------------------------------------------------------- */
  const handleAttachmentsChange = (e) => {
    setAttachments(e.target.files);  // Store selected files in state
  };

  /* ----------------------------------------------------- SEND EMAIL TO USERS ----------------------------------------------------------------------- */
  const handleSendEmails = async (e) => {
    e.preventDefault(); // Prevent the form from submitting the traditional way
    setLoading(true);

    const formData = new FormData();

    // Append emails, subject, and message to formData
    users.forEach(user => {
      formData.append('emails', user.user_Email); // Append each email individually
    });
    
    formData.append('subject', subject);
    formData.append('message', message);

    // Check if attachments exist
    if (attachments && attachments.length > 0) {
      // Attachments exist, append files to formData
      for (let i = 0; i < attachments.length; i++) {
        formData.append('attachments', attachments[i]);
      }

      // Call the endpoint for emails with attachments
      try {
        const response = await axios.post(`${apiUrl}/api/Admin/sendEmailsWithAttachments`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'  // Required for file uploads
          }
        });

        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Emails Sent',
            text: 'Emails with attachments were sent successfully!',
          });
          setMessage("");
          setSubject("");
          setAttachments([]);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to send emails with attachments.',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while sending emails with attachments.',
        });
        console.error('Error sending emails with attachments:', error);
      } 
      finally {
        setLoading(false); // Stop the loading animation
      }
    } else {
      // No attachments, send a normal email without attachments
      try {
        const response = await axios.post(`${apiUrl}/api/Admin/sendEmailsWithoutAttachments`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'  // For form data without attachments as well
          }
        });

        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Emails Sent',
            text: 'Emails were sent successfully!',
          });
          setMessage("");
          setSubject("");
          setAttachments([]);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to send emails.',
          });
        }
      } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'An error occurred while sending emails.',
          });
          console.error('Error sending emails:', error);
      } 
      finally {
        setLoading(false); // Stop the loading animation
      }
    }
};


  /* ----------------------------------------------------- HTML ----------------------------------------------------------------------------------- */
  return (
    <div className="user-container">
      <div className="main-table-container">
        <h2>User List</h2>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Country</th>
              <th>City</th>
              <th>Select</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.user_ID}</td>
                <td>{user.user_FN}</td>
                <td>{user.user_LN}</td>
                <td>{user.user_Email}</td>
                <td>+{user.user_Phone}</td>
                <td>{user.user_Country}</td>
                <td>{user.user_City}</td>
                <td>
                    <button 
                        className='table-select-button' 
                        onClick={() => handleSelect(user.user_Email, user.user_ID)}
                    >
                        Select
                    </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="form-container">
        <h2>Add User To Transaction</h2>
        <form onSubmit={handleAddUserToTransaction}>
          <div className="form-group">
            <label htmlFor="courseID">Enter Course ID: </label>
            <input className='user-input'  onChange={(e) => setCourseID(e.target.value)} ></input>
            <label htmlFor="users">Selected Users:</label>
            <textarea
              id="users"
              name="users"
              rows="5"
              className='user-input'
              value={userEmails.join("\n")}
              readOnly
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className={`send-email-button ${loading ? 'loading' : ''}`}
            disabled={loading}
          >
            {loading ? "Sending..." : "Add Users To Transaction"}
          </button>
          <button
            type='button'
            onClick={handleRemoveLastEmail}
            className={`add-form-remove-button ${loading ? 'loading' : ''}`}
            disabled={loading}
          >
            {loading ? "Sending..." : "Remove Last Email"}
          </button>
        </form>
      </div>

      <div className="form-container">
        <h2>Send Email to All Users</h2>
        <form onSubmit={handleSendEmails} encType="multipart/form-data">
          <div className="form-group">
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              id="subject"
              name="subject"
              className='user-input'
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              className='user-input'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="attachments">Attachments (Optional):</label>
            <input
              type="file"
              id="attachments"
              name="attachments"
              multiple
              onChange={handleAttachmentsChange}
            />
          </div>
          <button
            type="submit"
            className={`send-email-button ${loading ? 'loading' : ''}`}
            disabled={loading}
          >
            {loading ? "Sending..." : "Send Email"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default User;