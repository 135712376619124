import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './Header.css';
import Logo from '../assets/logo.png';
import BARS from '../assets/bars.png';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user ID exists in session storage
    const userId = localStorage.getItem('user_id');
    if (userId) {
      setIsLoggedIn(true);
    }
  }, []); // Empty dependency array to run only once

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleAuthButtonClick = () => {
    if (isLoggedIn) {
      // Handle logout
      localStorage.removeItem('user_id');
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      setIsLoggedIn(false);  // Update state
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "You Have Been Logged Out",
        showConfirmButton: false,
        timer: 1500
      });
      navigate('/');  // Ensure this happens after state update
    } else {
      navigate('/signin');
    }
  };

  return (
    <section id="header" className='header__section container'>
      <div className='nav__main'>
        <div className='nav__left'>
          <img src={Logo} alt="Kempton Academy Logo" className='nav__logo' />
          <h1 className='nav__title'>Kempton Academy</h1>
        </div>
        <div className='nav__right'>
          <img src={BARS} alt="Menu" className='nav__bar' onClick={toggleMenu} />
          <div className={`nav__menu ${isMenuOpen ? 'open' : ''}`}>
            <p className='nav__menu__item' onClick={() => navigate('/')}>Home</p>
            <p className='nav__menu__item' onClick={() => navigate('/courses')}>Courses</p>
            <p className='nav__menu__item' onClick={() => navigate('/terms')}>Terms & Conditions</p>
            <p className='nav__menu__item' onClick={() => navigate('/how-it-works')}>How It Works</p>
            <p className='nav__menu__item' onClick={() => navigate('/contact-us')}>Contact Us</p>
            <button className='nav__btn' onClick={handleAuthButtonClick}>
              {isLoggedIn ? 'Logout' : 'Sign In'}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
