import React from 'react';
import './How.css';

function How() {
  const items = [
    {
      num: '01',
      header: 'Create an Account',
      para: 'Create an account with Kimpton Academy to be able to buy a course.',
    },
    {
      num: '02',
      header: 'Purchase a Course',
      para: 'Once you have created an account, you can purchase one of our courses via bank transfer.',
    },
    {
      num:  '03',
      header: 'Recieve Confirmation Email',
      para: 'After purchasing a course, you will recieve an automated confirmation email with the recepit of your purchase.',
    },
    {
        num:  '04',
        header: 'Recieve Your Timetable',
        para: 'After confirmation, before the course date commences you will recieve your timetable with Zoom Meeting links to conduct your lessons.',
    }
  ];

  return (
    <div className='how__main'>
      <h1 className='how__title'>How We Deliever Our Courses !</h1>
      <div className='how__item__container'>
        {items.map((item, index) => (
          <div className='how__item' key={index}>
            <h1 className='how__num'>{item.num}</h1>
            <div className='how__content'>
                <h1 className='how__header'>{item.header}</h1>
                <p className='how__para'>{item.para}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default How;
