import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import {jwtDecode} from 'jwt-decode'; 
import './ForgotPass.css';
import axios from 'axios';

export default function ForgotPass() {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

/* ----------------------------------------------------- VALIDATE TOKEN ------------------------------------------------------------------------------- */
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenFromURL = queryParams.get('token');
    
    if (!tokenFromURL) {
      navigate('/signin'); // Navigate to sign-in page if no token exists
      return;
    }

    try {
      const decoded = jwtDecode(tokenFromURL); // Decode the token after extracting it
      setEmail(decoded.email);
      setToken(tokenFromURL);
    } catch (error) {
      console.error("Invalid or expired token:", error);
      Swal.fire({
        icon: 'error',
        title: 'Invalid or expired link',
        text: 'The reset password link is invalid or has expired. Please request a new one.',
      });
      navigate('/signin'); // Redirect to sign-in if token is invalid
    }
  }, [navigate]);

/* ----------------------------------------------------- HANDLE PASSWORD RESET ------------------------------------------------------------------------------- */
const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Regex to validate that the password has at least 10 characters and at least 1 number
    const passwordRegex = /^(?=.*\d).{10,}$/;
  
    // Basic password match and regex validation
    if (password !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Passwords do not match!',
      });
      return;
    }
  
    if (!passwordRegex.test(password)) {
      Swal.fire({
        icon: 'warning',
        title: 'Invalid Password',
        text: 'Password must be at least 10 characters long and contain at least one number.',
      });
      return;
    }
  
    const data = {
      Email: email,
      Password: confirmPassword,
    };
  
    try {
      const response = await axios.put(`${apiUrl}/api/User/updatePassword`, data, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Your Password Has Been Updated!',
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => { navigate('/signin'); }, 2000);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401 error specifically
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'This link has expired. Please request a new password reset link.',
        });
      } else {
        console.error("Catch Error Updating Password:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while updating the password. Please try again later.',
        });
      }
    }
  };  
/* ----------------------------------------------------- HTML ------------------------------------------------------------------------------- */
  return (
    <div className="reset-pass-container">
      <h2 className="reset-pass-heading">Reset Your Password</h2>
      <form className="reset-pass-form" onSubmit={handleSubmit}>
        <div className="reset-pass-form-group">
          <label htmlFor="password" className="reset-pass-label">New Password</label>
          <input
            type="password"
            id="password"
            name="password"
            className="reset-pass-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="reset-pass-form-group">
          <label htmlFor="confirmPassword" className="reset-pass-label">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            className="reset-pass-input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="reset-pass-submit-btn">Reset Password</button>
      </form>
    </div>
  );
}
