import React from 'react';
import { Link } from 'react-scroll';
import './Home.css';
import Video from '../../assets/video.mp4';

function Home() {
  return (
    <section id="home">
      <div className='home__main'>
        <div className='home__content'>
          <h1 className='home__h1'>Achieve Your PTE Goals with Kempton Academy</h1>
          <h2 className='home__h2'>Join Our Expert Coaching Program</h2>
          <Link 
            to="about"
            spy={true} 
            smooth={true} 
            offset={-100} 
            duration={500}
          >
            <button className='home__btn'>Learn More</button>
          </Link>
        </div>
        <div className='home__video'>
          <video controls autoPlay loop src={Video} className='vidd'></video>
        </div>
      </div>
    </section>
  );
}

export default Home;