import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../table.css';
import './Tutor.css';
import '../form.css';

function Tutor() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    tutor_ID: '',
    tutor_FN: '',
    tutor_LN: '',
    tutor_Email: '',
    tutor_Phone: '',
    tutor_Bio: '',
    tutor_Linkedin: '',
  });
/* ----------------------------------------------------- DISPLAY TUTOR ------------------------------------------------------------------------------- */
  useEffect(() => {
    const fetchTutors = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/Admin/getAllTutors`, { 
          headers: {
              'Authorization': `Bearer ${token}`,
              'ngrok-skip-browser-warning': 'true'
          }
        });
        if (response.status === 200) {
          setUsers(response.data);
        } else {
          console.log('Failed to fetch tutors:', response.error);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Session expired, handle unauthorized case
          Swal.fire({
            icon: 'warning',
            title: 'Session Expired',
            text: 'Your session has expired. Please log out and log back in.',
            confirmButtonText: 'OK'
          }).then(() => {
            // Clear the token and redirect to login
            localStorage.removeItem('token');
            window.location.href = '/signin';
          });
        }
        else{
          console.log('Error fetching tutors:', error);
        }
      }
    };

    fetchTutors();
  }, [apiUrl, token]);
/* ----------------------------------------------------- ASSIGN DATA ---------------------------------------------------------------------------------- */
  const handleSetData = (tutor) => {
    setFormData(tutor);
  };
/* ----------------------------------------------------- FIELD CHANGE --------------------------------------------------------------------------------- */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
/* ----------------------------------------------------- ADD TUTOR ------------------------------------------------------------------------------------- */
  const handleAddTutor = async () => {
    if (!formData.tutor_FN || !formData.tutor_LN || !formData.tutor_Email || !formData.tutor_Phone || !formData.tutor_Bio || !formData.tutor_Linkedin) {
      Swal.fire('Warning', 'Please fill in all required fields.', 'warning');
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/api/Admin/AddTutor`, 
        {
          Tutor_FN: formData.tutor_FN,
          Tutor_LN: formData.tutor_LN,
          Tutor_Email: formData.tutor_Email,
          Tutor_Phone: parseInt(formData.tutor_Phone),
          Tutor_Bio: formData.tutor_Bio,
          Tutor_Linkedin: formData.tutor_Linkedin,
        },
        { 
          headers: {
              'Authorization': `Bearer ${token}`
          }
        }
      );
  
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Tutor Added",
          showConfirmButton: false,
          timer: 1500
        });
        setTimeout(() => { window.location.reload(); }, 1500);
      } else {
        Swal.fire('Error', 'Failed to add tutor', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'An error occurred while adding the tutor', 'error');
    }
  };
/* ----------------------------------------------------- EDIT TUTOR ----------------------------------------------------------------------------------- */
  const handleEditTutor = async () => {
    if (!formData.tutor_FN || !formData.tutor_LN || !formData.tutor_Email || !formData.tutor_Phone || !formData.tutor_Bio || !formData.tutor_Linkedin) {
      Swal.fire('Warning', 'Please fill in all required fields.', 'warning');
      return;
    }

    try {
      const response = await axios.put(`${apiUrl}/api/Admin/EditTutor`, 
        {
          Tutor_ID: parseInt(formData.tutor_ID),
          Tutor_FN: formData.tutor_FN,
          Tutor_LN: formData.tutor_LN,
          Tutor_Email: formData.tutor_Email,
          Tutor_Phone: parseInt(formData.tutor_Phone),
          Tutor_Bio: formData.tutor_Bio,
          Tutor_Linkedin: formData.tutor_Linkedin
        },
        { 
          headers: {
              'Authorization': `Bearer ${token}`
          }
        }
    );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Tutor Updated",
          showConfirmButton: false,
          timer: 1500
        });
        setTimeout(() => { window.location.reload(); }, 1500);
      } else {
        Swal.fire('Error', 'Failed to edit tutor', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'An error occurred while editing the tutor', 'error');
    }
  };
/* ----------------------------------------------------- DELETE TUTOR ----------------------------------------------------------------------------------- */
  const handleDeleteTutor = async () => {
    if (!formData.tutor_ID) {
        Swal.fire('Warning', 'Please Select A Tutor To Delete', 'warning');
        return;
    }

    try {
        const response = await axios.delete(`${apiUrl}/api/Admin/DeleteTutor/${parseInt(formData.tutor_ID)}`, { 
          headers: {
              'Authorization': `Bearer ${token}`
          }
        }); // In a delete HTTP request, you cant send through body
        if (response.status === 200) {
            Swal.fire({
                icon: "success",
                title: "Tutor Deleted",
                showConfirmButton: false,
                timer: 2000
            });
            setTimeout(() => { window.location.reload(); }, 2000);
        } else {
            Swal.fire('Error', 'Failed to delete tutor', 'error');
        }
    } catch (error) {
        Swal.fire('Error', 'An error occurred while deleting the tutor', 'error');
    }
  };
/* ------------------------------------------------ HTML ----------------------------------------------------------------------------------------------- */
  return (
    <div className='tutor__main'>
      <div className="main-table-container">
        <h2>Tutor List</h2>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th># ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.tutor_ID}</td>
                <td>{user.tutor_FN}</td>
                <td>{user.tutor_LN}</td>
                <td>{user.tutor_Email}</td>
                <td>+{user.tutor_Phone}</td>
                <td>
                  <button 
                    className='table-select-button' 
                    onClick={() => handleSetData(user)}
                  >
                    Select
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="form-container">
        <h2>Customize Tutor</h2>
        <form className='form'>
          <div className="form-group">
            <label htmlFor="tutor-id">Tutor ID</label>
            <input 
              type="text" 
              id="tutor-id" 
              name="tutor_ID" 
              className="user-input" 
              readOnly 
              style={{ backgroundColor: "#f0f0f0" }} 
              value={formData.tutor_ID} 
            />
          </div>
          <div className="form-group">
            <label htmlFor="tutor-first-name">Tutor First Name</label>
            <input 
              type="text" 
              id="tutor-first-name" 
              name="tutor_FN" 
              className='user-input' 
              required 
              value={formData.tutor_FN} 
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="tutor-last-name">Tutor Last Name</label>
            <input 
              type="text" 
              id="tutor-last-name" 
              name="tutor_LN" 
              className='user-input' 
              required 
              value={formData.tutor_LN} 
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="tutor-email">Tutor Email</label>
            <input 
              type="email" 
              id="tutor-email" 
              name="tutor_Email" 
              className='user-input' 
              required 
              value={formData.tutor_Email} 
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="tutor-phone">Tutor Phone</label>
            <input 
              type="tel" 
              id="tutor-phone" 
              name="tutor_Phone" 
              className='user-input' 
              required 
              value={formData.tutor_Phone} 
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="tutor-bio">Tutor Bio</label>
            <textarea 
              id="tutor-bio" 
              name="tutor_Bio" 
              rows="5" 
              cols="100" 
              className='bio-input' 
              required 
              value={formData.tutor_Bio} 
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="tutor-linkedin">Tutor LinkedIn</label>
            <input 
              type="url" 
              id="tutor-linkedin" 
              name="tutor_Linkedin" 
              className='user-input' 
              required 
              value={formData.tutor_Linkedin} 
              onChange={handleChange}
            />
          </div>
        </form>
        <div className='form__btn'>
          <button type="button" className="form-add-button" onClick={handleAddTutor}>Add Tutor</button>
          <button type="button" className="form-edit-button" onClick={handleEditTutor}>Edit Tutor</button>
          <button type="button" className="form-delete-button" onClick={handleDeleteTutor}>Delete Tutor</button>
        </div>
      </div>
    </div>
  );
}

export default Tutor;
