import React, {useEffect} from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import Footer from './components/Footer';
import AdminLayout from '../src/pages/ADMIN FOLDER/AdminLayout';
import SocialFooter from './components/socialFooter';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Team from './pages/Team/Teams';
import Terms from './pages/Terms/Terms';
import How from './pages/How/How';
import Course from './pages/Courses/Course';
import CourseDetails from './pages/Course Details/CourseDetails';
import SignIn from './pages/Login/SignIn';
import SignUp from './pages/Signup/SignUp';
import Verification from './pages/Verification/Verification';
import Contact from './pages/Contact Us/Contact';
import TermsC from './pages/Terms/Terms Pages/TermsC';
import Refund from './pages/Terms/Terms Pages/Refund';
import Privacy from './pages/Terms/Terms Pages/Privacy'
import User from './pages/ADMIN FOLDER/Users/User';
import Tutor from './pages/ADMIN FOLDER/Tutors/Tutor';
import AdminCourse from './pages/ADMIN FOLDER/adminCourses/adminCourse';
import Transaction from './pages/ADMIN FOLDER/Transactions/Transaction';
import Timetable from './pages/ADMIN FOLDER/Timetable/Timetable';
import ForgotPass from './pages/Forgot Password/ForgotPass';
import Control from './pages/ADMIN FOLDER/Control/Control';

function Layout() {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const course_ID = sessionStorage.getItem("course_ID");
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  useEffect(() => {
    if (isAdminRoute && (role !== "Admin" || role === null)) {
      navigate("/");  // Redirect if not Admin after loading is complete
    }
  }, [isAdminRoute, role, navigate]); // Re-run when role or loading state changes

  useEffect(() => {
    if (!course_ID && location.pathname === '/course-details') {
      // If no course_ID exists in sessionStorage and the user tries to access course details, redirect them to courses page
      navigate('/courses');
    }
  }, [course_ID, location.pathname, navigate]);


  if (isAdminRoute) {
    return (
      <AdminLayout>
        <Routes>
          <Route path="/admin-panel" element={<User />} />
          <Route path="/admin/view-tutors" element={<Tutor />} />
          <Route path="/admin/view-courses" element={<AdminCourse />} />
          <Route path="/admin/view-transactions" element={<Transaction />} />
          <Route path="/admin/view-users-tutors" element={<Timetable />} />
          <Route path="/admin/view-controls" element={<Control/>} />
        </Routes>
      </AdminLayout>
    );
  }

  const hideFooter = location.pathname === '/signin' 
  || location.pathname === '/signup' 
  || location.pathname === '/account-verification' 
  || location.pathname === '/terms' 
  || location.pathname === '/courses' 
  || location.pathname === '/course-details' 
  || location.pathname === '/how-it-works' 
  || location.pathname === '/contact-us' 
  || location.pathname === '/t&c' 
  || location.pathname === '/refund-policy' 
  || location.pathname === '/privacy-policy' 
  || location.pathname === '/forgot-password'; // Fixed here

const hideHeader = location.pathname === '/signin' 
  || location.pathname === '/signup' 
  || location.pathname === '/account-verification' 
  || location.pathname === '/forgot-password'; // Fixed here

const hideSocial = location.pathname === '/signin' 
  || location.pathname === '/signup' 
  || location.pathname === "/" 
  || location.pathname === '/account-verification' 
  || location.pathname === '/forgot-password'; // Fixed here


  return (
    <>
      {!hideHeader && <Header />}
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Home />
              <About />
              <Team />
            </>
          }
        />
        <Route path="/terms" element={<Terms />} />
        <Route path="/how-it-works" element={<How />} />
        <Route path="/courses" element={<Course />} />
        <Route path="/course-details" element={<CourseDetails />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/account-verification" element={<Verification />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/t&c" element={<TermsC />} />
        <Route path="/refund-policy" element={<Refund />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/forgot-password" element={<ForgotPass />} />
      </Routes>
      {!hideSocial && <SocialFooter />}
      {!hideFooter && <Footer />}
    </>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Layout />
    </Router>
  );
}

export default App;