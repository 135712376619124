import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Teams.css';
import Linked from '../../assets/linkedin.png';

function Teams() {
    const apiUrl = process.env.REACT_APP_API_URL; // Load API from ENV
    const [users, setUsers] = useState([]);
    const subjects = [
        { number: "01", text: "Internationally based tutors with a strong grasp of the English language as well as the PTE exam." },
        { number: "02", text: "Course crafted in collaboration with high-achieving, high-scoring PTE students, inclusive of exam applicable tips and tricks." },
        { number: "03", text: "Virtually delivered course with all course materials available for download. Learn from the comfort of your home!" }
    ];
    
/* ----------------------------------------------------- DISPLAY TUTORS ------------------------------------------------------------------------------- */
useEffect(() => {
    const fetchTutors = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/Tutor/getTutors`, {
                headers: {
                  'ngrok-skip-browser-warning': 'true'
                }
              })
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching tutors:', error);
            setUsers([])
        }
    };
    fetchTutors();
}, [apiUrl]);
/* ----------------------------------------------------- HTML ------------------------------------------------------------------------------- */
    return (
        <section id='team'>
            <div className='team__main'>

                <div className='team__headers'>
                    <h1 className='team__title'>Meet The Team</h1>
                    <p className='team__para'>At Kempton Academy, our dedicated team is passionate about helping students achieve their academic goals. With a focus on excellence and a deep understanding of the PTE exam, we're committed to providing the best resources and support to ensure your success. Get to know the experts who are here to guide you every step of the way!</p>
                </div>

                <div className='team__members'>
                    {users.length > 0 ? (
                        users.map((user, index) => (
                            <div className='team__item' key={index}>
                                <h1 className='team__item__name'>{user.tutor_FullName}</h1>
                                <p className='team__item__bio'>{user.tutor_Bio}</p>
                                <div className='team__item__social'>
                                    <a href={user.tutor_Linkedin} target="_blank" rel="noopener noreferrer">
                                        <img src={Linked} alt="LinkedIn" className="team__social_img" />
                                    </a>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No tutors found.</p>
                    )}
                </div>
                
                <div className='choose__main'>
                    <h1 className='choose__header'>Why Choose Kempton Academy</h1>
                    <p className='choose__text'>At Kempton, we don’t just teach, we inspire. We are committed to your success and will support you every step of the way on your journey to mastering the PTE academic exam. Our approach is student-centred, results-driven, and focussed on delivering real value.</p>
                </div>
                
                <div className='choose__subject'>
                    {subjects.map((subject, index) => (
                        <div className='choose__item' key={index}>
                            <h1 className='item__num'>{subject.number}</h1>
                            <p className='item__para'>{subject.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Teams;
